/*MENU PRINCIPAL*/
#menu{height: 17px; width: 700px;display: inline-block;vertical-align: top;font-size: 0px;}
#menu .sf-menu a {font:15px/15px 'roboto-regular', Arial, Helvetica, sans-serif; color: #000; text-decoration: none; padding-right: 6px;  }
#menu .sf-menu li { margin-right:6px; float: left; border-right: 1px solid #e5e5e5; }
#menu .sf-menu li:last-child { margin-right:0; border-right:0px; border-right: none !important; }
#menu .sf-menu li a:hover {color: #ff141c}
#menu .sf-menu li ul { position: absolute; background: #50668f; z-index: 1000000;  }
#menu .sf-menu li ul ul{position:absolute; top: 0; }
#menu .sf-menu li ul li { border: 0 none; margin: 0; float:left;}
#menu .sf-menu li ul li a { font-size: 14px !important;color:#fff; padding:0 10px !important }
#menu .sf-menu li ul li:hover {}
#menu .sf-menu li ul li a:hover {padding: 0; border: none; color:#000}
#menu .sf-sub-indicator { position: absolute; display: none; right: .75em; top: 1.05em; /* IE6 only */ width: 10px; height: 10px; text-indent: -999em; overflow: hidden; background: url('../images/default/arrows-ffffff2.png') no-repeat -10px -100px; }
#menu a > .sf-sub-indicator { /* give all except IE6 the correct values */ top: 65%; background-position: 0 -100px; left: 44%; }
/* apply hovers to modern browsers *//*#menu a:focus > .sf-sub-indicator, a:hover > .sf-sub-indicator, a:active > .sf-sub-indicator, li:hover > a > .sf-sub-indicator, li.sfHover > a > .sf-sub-indicator {background-position: -10px -100px;   }*/
/* point right for anchors in subs */
#menu .sf-menu ul .sf-sub-indicator { display:none; background-position: -10px 0; }
#menu .sf-menu ul a > .sf-sub-indicator { display:none; background-position: 0 0; }
/* apply hovers to modern browsers */
#menu .sf-menu ul a:focus > .sf-sub-indicator, .sf-menu ul a:hover > .sf-sub-indicator, .sf-menu ul a:active > .sf-sub-indicator, .sf-menu ul li:hover > a > .sf-sub-indicator, .sf-menu ul li.sfHover > a > .sf-sub-indicator {display:none;  background-position: -10px 0; /* arrow hovers for modern browsers*/ }
/*** shadows for all but IE6 ***/
/*#main-nav .sf-shadow ul { background: #url('../images/shadow.png') no-repeat bottom right; padding: 0 8px 9px 0; -moz-border-radius-bottomleft: 17px; -moz-border-radius-topright: 17px; -webkit-border-top-right-radius: 17px; -webkit-border-bottom-left-radius: 17px; }
#main-nav .sf-shadow ul.sf-shadow-off { background: transparent; }*/

/* HEADER NAV */

#container-header header #nav-header{float: left; margin-top: 20px; margin-bottom: 5px;}
#container-header header #nav-header{float: left;}
#container-header header #nav-header ul li{float: left; margin-right: 7px; color: #7ea1b4; border-right: 1px solid #7b9fb3;}
#container-header header #nav-header ul li:last-child{border-right: 0px;}
#container-header header #nav-header ul li a{font:12px/12px "oswald-light", Arial, Helvetica, sans-serif; color: #fff; padding-right: 7px; text-transform: uppercase;}

/* FOOTER NAV */

#footer-nav{padding-top: 7px; height: 30px; background: #25495d;}

#footer-nav .sf-menu a {font:16px/16px 'oswald-regular', Arial, Helvetica, sans-serif; color: #fff; text-decoration: none; text-transform: uppercase;  }
#footer-nav .sf-menu li:first-child { margin-left:20px; margin-right: 15px;}
#footer-nav .sf-menu li:last-child { margin-right:0;}
#footer-nav .sf-menu li { margin-right:10px; float: left; }
#footer-nav .sf-menu li a:hover {text-decoration: underline;}
#footer-nav .sf-menu li ul { position: absolute; background: #B70F2F; z-index: 1000000; margin-top: -62px;}
#footer-nav .sf-menu li ul ul{position:absolute; top: 0; }
#footer-nav .sf-menu li ul li { border: 0 none; margin: 0; padding:10px 0; float:left;}
#footer-nav .sf-menu li ul li a { font-size: 14px !important;color:#fff !important; padding:0 10px !important }
#footer-nav .sf-menu li ul li:hover {background:#FF141E;}
#footer-nav .sf-menu li ul li a:hover {padding: 0; border: none;}
#footer-nav .sf-sub-indicator { position: absolute; display: none; right: .75em; top: 1.05em; /* IE6 only */ width: 10px; height: 10px; text-indent: -999em; overflow: hidden; background: url('../images/default/arrows-ffffff2.png') no-repeat -10px -100px; }
#footer-nav a > .sf-sub-indicator { /* give all except IE6 the correct values */ top: 65%; background-position: 0 -100px; left: 44%; }
/* apply hovers to modern browsers *//*#footer-nav a:focus > .sf-sub-indicator, a:hover > .sf-sub-indicator, a:active > .sf-sub-indicator, li:hover > a > .sf-sub-indicator, li.sfHover > a > .sf-sub-indicator {background-position: -10px -100px;     }*/
/* point right for anchors in subs */
#footer-nav .sf-menu ul .sf-sub-indicator { display:none; background-position: -10px 0; }
#footer-nav .sf-menu ul a > .sf-sub-indicator { display:none; background-position: 0 0; }
/* apply hovers to modern browsers */
#footer-nav .sf-menu ul a:focus > .sf-sub-indicator, .sf-menu ul a:hover > .sf-sub-indicator, .sf-menu ul a:active > .sf-sub-indicator, .sf-menu ul li:hover > a > .sf-sub-indicator, .sf-menu ul li.sfHover > a > .sf-sub-indicator {display:none;  background-position: -10px 0; /* arrow hovers for modern browsers*/ }
/*** shadows for all but IE6 ***/
/*#footer-nav .sf-shadow ul { background: #url('../images/shadow.png') no-repeat bottom right; padding: 0 8px 9px 0; -moz-border-radius-bottomleft: 17px; -moz-border-radius-topright: 17px; -webkit-border-top-right-radius: 17px; -webkit-border-bottom-left-radius: 17px; }
#footer-nav .sf-shadow ul.sf-shadow-off { background: transparent; }*/