.divider {
    margin-bottom: 20px;
    padding-bottom: 20px;
    background: url(/images/border.png) repeat-x bottom center;
}

.divider2 {
    margin-bottom: 20px;
}

.Buttons {
    padding: 10px;
    font: 12px/12px"oswald-regular", Arial, Helvetica, sans-serif;
    color: #fff;
    background: #213b50;
    margin-right: 20px;
    float: left;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
}
.Buttons a {
    color: #fff !important;
    text-decoration: none
}
.Buttons a:hover {
    color: #fff;
    text-decoration: underline;
}
.Buttons_desativado {
    padding: 10px;
    font: 12px/12px"oswald-regular", Arial, Helvetica, sans-serif;
    color: #fff;
    background: #213b50;
    margin-right: 20px;
    float: left;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
}
.Buttons_desativado a {
    color: #fff;
    text-decoration: none
}
.readmore {
    float: right !important;
    margin-top: 20px;
    margin-right: 0px
}
.ButtonSubmit {
    border: none;
    padding: 10px;
    font: 12px/12px"oswald-regular", Arial, Helvetica, sans-serif;
    color: #fff;
    background: #213b50;
    margin-right: 20px;
    float: left;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
}
/* DEFAULT FONTS */

.Font12Arial {
    font: 12px/18px Arial;
}
.Font14Arial {
    font: 14px/14px Arial;
}
.Font14Arial {
    font: 14px/14px Arial;
}
.Font16Arial {
    font: 16px/16px Arial;
}
.Font18Arial {
    font: 18px/18px Arial;
}
.Font20Arial {
    font: 20px/18px Arial;
}
.Font30Arial {
    font: 30px/48px Arial;
}
/* COLORS */

.black {
    color: #000;
}
.white {
    color: #fff !important;
}
.red {
    color: #b80019
}
.gray {
    color: #444
}
.orange {
    color: #efa80f;
}
/* DEFAULT CLASS */

.border-right {
    border-right: 1px solid #a7a7a7
}
.bold {
    font-weight: bold
}
.nofloat {
    float: none !important;
}
.right {
    float: right;
}
.left {
    float: left;
}
.linespacing {
    margin-bottom: 20px;
}
.clear:after {
    clear: both;
}
.clear:before,
.clear:after {
    content: " ";
    display: table;
}
.alignright {
    text-align: right;
}
.aligncenter {
    text-align: center;
}
.vertical-align {
    display: inline-block;
    vertical-align: middle;
}
.uppercase {
    text-transform: uppercase;
}
.bold {
    font-weight: bold;
}
.background-none {
    background: none !important;
}
.padding-none {
    padding: 0 !important;
}
.padding-left-none {
    padding-left: 0;
}
.margin-none {
    margin: 0 !important
}
.margin-right-none {
    margin-right: 0px;
}
.border-none {
    border: none !important;
}
.marginleft2 {
    margin-left: 2px
}
.marginleft10 {
    margin-left: 10px
}
.marginleft15 {
    margin-left: 15px
}
.marginleft12 {
    margin-left: 12px
}
.marginleft20 {
    margin-left: 20px
}
.marginleft30 {
    margin-left: 30px;
}
.marginleft40 {
    margin-left: 40px;
}
.marginleft50 {
    margin-left: 50px;
}
.marginleft60 {
    margin-left: 60px
}
.marginleft70 {
    margin-left: 70px
}
.margintop3 {
    margin-top: 3px;
}
.margintop2 {
    margin-top: 2px
}
.margintop5 {
    margin-top: 5px
}
.margintop6 {
    margin-top: 6px
}
.margintop8 {
    margin-top: 8px
}
.margintop10 {
    margin-top: 10px
}
.margintop12 {
    margin-top: 12px
}
.margintop15 {
    margin-top: 15px
}
.margintop18 {
    margin-top: 18px
}
.margintop20 {
    margin-top: 20px
}
.margintop25 {
    margin-top: 25px
}
.margintop30 {
    margin-top: 30px
}
.margintop35 {
    margin-top: 35px
}
.margintop40 {
    margin-top: 40px
}
.margintop45 {
    margin-top: 45px
}
.margintop47 {
    margin-top: 47px
}
.margintop52 {
    margin-top: 52px;
}
.margintop38 {
    margin-top: 38px
}
.margintop60 {
    margin-top: 60px
}
.margintop57 {
    margin-top: 57px
}
.margintop50 {
    margin-top: 50px
}
.margintop70 {
    margin-top: 70px;
}
.margintop80 {
    margin-top: 80px;
}
.margintop90 {
    margin-top: 90px;
}
.margintop100 {
    margin-top: 100px;
}
.paddingtop5 {
    padding-top: 5px
}
.paddingtop10 {
    padding-top: 10px
}
.paddingtop15 {
    padding-top: 15px
}
.paddingtop20 {
    padding-top: 20px
}
.paddingtop30 {
    padding-top: 30px
}
.paddingtop35 {
    padding-top: 35px
}
.paddingtop40 {
    padding-top: 40px
}
.paddingtop50 {
    padding-top: 50px;
}
.marginright2 {
    margin-right: 2px;
}
.marginright5 {
    margin-right: 5px;
}
.marginright10 {
    margin-right: 10px;
}
.marginright15 {
    margin-right: 15px;
}
.marginright20 {
    margin-right: 20px;
}
.marginright21 {
    margin-right: 21px;
}
.marginright30 {
    margin-right: 30px;
}
.marginright45 {
    margin-right: 45px;
}
.marginright350 {
    margin-right: 350px;
}
.marginright100 {
    margin-right: 100px;
}
.marginright90 {
    margin-right: 90px;
}
.marginright80 {
    margin-right: 80px;
}
.marginright70 {
    margin-right: 70px;
}
.marginright60 {
    margin-right: 60px;
}
.marginright50 {
    margin-right: 50px;
}
.marginright40 {
    margin-right: 40px;
}
.paddingright10 {
    padding-right: 10px;
}
.paddingright30 {
    padding-right: 30px;
}
.paddingright60 {
    padding-right: 60px;
}
.paddingright40 {
    padding-right: 40px;
}
.marginbottom100 {
    margin-bottom: 100px;
}
.marginbottom20 {
    margin-bottom: 20px;
}
.marginbottom35 {
    margin-bottom: 35px;
}
.marginbottom15 {
    margin-bottom: 15px;
}
.marginbottom25 {
    margin-bottom: 25px;
}
.marginbottom10 {
    margin-bottom: 10px;
}
.marginbottom5 {
    margin-bottom: 5px;
}
.marginbottom2 {
    margin-bottom: 2px;
}
.paddingbottom20 {
    padding-bottom: 20px;
}
.paddingbottom15 {
    padding-bottom: 15px;
}
.paddingtop30 {
    padding-top: 30px;
}
.marginbottom30 {
    margin-bottom: 30px;
}
.paddingbottom30 {
    padding-bottom: 30px;
}
.marginbottom40 {
    margin-bottom: 40px;
}
.paddingbottom40 {
    padding-bottom: 40px;
}
.marginbottom50 {
    margin-bottom: 50px;
}
.paddingbottom50 {
    padding-bottom: 50px;
}
.marginbottom60 {
    margin-bottom: 60px;
}
.paddingbottom60 {
    padding-bottom: 60px;
}
.marginbottom70 {
    margin-bottom: 70px;
}
.paddingbottom70 {
    padding-bottom: 70px;
}