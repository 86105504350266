#mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9000;
    background-color: #000;
    display: none;
}
#close {
    float: left;
    width: 37px;
    position: relative;
    left: 286px;
    bottom: 30px;
}
#captcha-form {
    position: fixed;
    left: 35%;
    top: 30%;
    z-index: 9999;
    width: 315px;
    padding: 10px;
    background-color: #ffffff;
}

#texto-digite-captcha {
    font-family: Arial;
    font-size: 14px;
    margin-left: 30px;
}

input.button_custom_minor, #captcha-success-msg {
    font-family: Arial;
    font-size: 14px;
}

input.button_custom_minor {
    height: 36px;
}
