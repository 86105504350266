.bar-gray {
    height: 33px;
    width: 729px;
    background: url(/images/bar-confira.png?v=12) repeat-x;
    margin-bottom: 20px;
    font: 12px/12px Arial, Helvetica, sans-serif;
    padding: 10px 20px;
    text-transform: uppercase;
}

.ListaTitulo {
    padding: 10px;
    padding-left: 0px;
    font: 24px/24px"oswald-regular", Arial, Helvetica, sans-serif;
    color: #333;
}

.ListaTitulo a {
    color: #d34e22
}

/*---------------------- LISTAGEM DE CONTEUDOS GENERICO ---------------------*/

.ListItens {
    width: 100%;
    margin-top: 10px
}
.ListItensImage {
    margin: 0 10px 10px 0
}
.ListItens li {
    width: 100%
}
.ListItens li:last-child {
    border: 0 none
}

/*---------------------- PAGINACAO DO CONTEUDO PADRAO DO SITE ---------------------*/

.PaginacaoNav {
    display: table;
    margin: 0 auto;
    width: 385px;
}
.Pagination {
    display: table;
    margin: 0 auto;
    width: 385px;
}
.PaginationComment {
    display: table;
    margin: 0 auto;
    width: 371px;
}