.hat {
    font: 11px/11px "roboto-bold", Arial, Helvetica, sans-serif;
    color: #f58634;
    text-transform: uppercase;
    float: left;
    margin-bottom: 5px;
}
.month {
    font: 18px/18px "roboto-bold", Arial, Helvetica, sans-serif;
    color: #f58220;
}
.credit {
    font: 9px/9px "roboto-regular", Arial, Helvetica, sans-serif;
    color: #b0b0b0;
    margin-bottom: 2px;
    float: right;
}

.call {
    font: 14px/20px Verdana, Arial, Helvetica, sans-serif;
    color: #333333;
}

.title-section {
    font: 26px/26px "oswald-bold", Arial, Helvetica, sans-serif;
    margin: 20px 0 0 10px;
}
.title-section-template {
    height: inherit;
    background: #92b1c2;
    padding: 8px 20px 6px;
    font: 16px/16px "geoslab", Arial, Helvetica, sans-serif;
    color: #fff;
    text-transform: uppercase;
    float: left;
}

.main-title {
    font: 42px/50px "roboto-bold", Arial, Helvetica, sans-serif;
    color: #000;
    margin-top: 20px;
}

.complement {
    font: 18px/27px "roboto-italic", Arial, Helvetica, sans-serif;
    color: #333333;
    margin-top: 20px;
}
.author {
    font: 18px/27px "roboto-italic", Arial, Helvetica, sans-serif;
    color: #333333;
    margin-top: 20px;
}

#hat-line {
    margin-bottom: 30px;
    font: 14px/14px "roboto-regular", Arial, Helvetica, sans-serif;
    color: #333333;
}

#hat-line-left {
    width: 563px;
    float: left;
    height: 20px;
}

#hat-line-right {
    width: 164px;
    float: left;
    height: 20px;
}

#twitter-widget-0 {
    width: 88px !important;
}

article .image-left {
    width: 120px;
    float: left;
    margin-right: 20px;
    height: 100px;
}

.text-content {
    font: 14px/23px Arial, Helvetica, sans-serif;
    color: #333333;
}

.text-content p {
    font: 14px/23px Arial, Helvetica, sans-serif;
    color: #333333;
}
.text-content ul {
    list-style: disc !important;
}
#actions-content {
    width: 359px;
    margin: 0 auto 20px auto;
}
#aval {
    position: relative;
    bottom: 5px
}

/*BLOCO DE CITACAO DA MATERIA*/

.quote-wrapper {
    display: table;
    margin: 10px 0;
    border-bottom: 2px solid #B70F2F;
}
.quote-wrapper.left {
    float: Left;
    margin: 10px 10px 10px 0
}
.quote-wrapper.right {
    float: right;
    margin: 10px 0 10px 10px
}
.quote-content {
    padding: 10px 0;
    float: left;
    width: 100%;
    font: normal 24px/32px 'DroidSansRegular', Arial, Helvetica, sans-serif !important;
    color: #333 !important
}

/*BLOCO DE IMAGEM DA MATERIA*/

.img-wrapper {
    display: table;
}
.img-content {
    border: 0px solid #E1E4E6;
}
.img-credit {
    float: right;
    font: normal 11px "TitilliumWebLight", Arial, Helvetica, sans-serif !important;
    color: #333 !important;
    padding-right: 8px;
}

.img-right {
    float: right;
    margin: 10px 0 10px 10px
}

.img-center {
    margin: 10px auto;
    padding: 0
}

.img-left {
    float: left;
    margin: 10px 10px 10px 0
}

.img-subtitle {
    padding: 5px 0 3px;
    margin: 0;
    width: 100%;
    color: #000;
    border-bottom: 0px solid #B70F2F;
    width: 100%;
    font: normal 12px "TitilliumWebLight", Arial, Helvetica, sans-serif !important;
    color: #333 !important;
    text-indent: 0 !important
}

.boxTemplate {
    float: left;
    border: 1px solid #D9E0E2;
    background: #F8F9FA;
    width: 178px;
    min-height: 30px
}

article #form-comment {
    display: none;
}
article label {
    font: 12px/12px Arial, Helvetica, sans-serif;
}
article input {
    margin-bottom: 10px;
    height: 30px;
}
article textarea {
    margin-bottom: 10px
}