html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
input,
select,
textarea {
    margin: 0;
    padding: 0
}
ol,
ul,
li {
    list-style: none;
}
img {
    border: none;
}
a {
    text-decoration: none;
    color: #000;
}
a:hover {
    text-decoration: underline;
}

pre  {
    font-size:  20px;
}

h6 {
    font: 12px/18px Arial, Helvetica, sans-serif;
}

h5 {
    font: 14px/22px Arial, Helvetica, sans-serif;
}

h4 {
    font: 16px/24px Arial, Helvetica, sans-serif;
}

h3 {
    font: 18px/27px Arial, Helvetica, sans-serif;
}

h2 {
    font: 20px/30px Arial, Helvetica, sans-serif;
}

h1 {
    font: 24px/36px Arial, Helvetica, sans-serif;
}

/* ---- Styling links ---- */

h6 a {
    color: #0162aa;
}

h5 a {
    color: #0162aa;
}

h4 a {
    color: #0162aa;
}

h3 a {
    color: #0162aa;
}

h2 a {
    color: #0162aa;
}

h1 a {
    color: #0162aa;
}