/* TEMPLATE 1 */

.template1-left {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-right: 1%;
}
.template1-right {
    width: 49%;
    display: inline-block;
    vertical-align: top;
}
/* TEMPLATE 2 */

.template2-left {
    width: 49%;
    display: inline-block;
    vertical-align: top;
}
.template2-right {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-left: 1%;
}

/* TEMPLATE 3 */

.template3-left {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.template3-right {
    width: 49%;
    display: inline-block;
    vertical-align: top;
}

.template3 .col-left {
    width: 43%;
    display: inline-block;
    vertical-align: top;
    margin-right: 7%;
}

.template3 .col-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.template3 .image {
    display: inline-block;
    vertical-align: top;
    width: 25%;
}

/* TEMPLATE 4 */

.template4 .col {
    width: 32%;
    display: inline-block;
    margin-right: 2%;
    vertical-align: top;
}

.template4 .col:last-child {
    margin-right: 0;
}

.template4 .title {
    font: 14px/16px Arial, Helvetica, sans-serif;
    color: #333;
    margin-bottom: 10px;
    float: none;
}
.template4 .hat {
    width: 95px;
}
/* TEMPLATE 5 */

.template5-left {
    width: 50%;
    display: inline-block;
    margin-right: 2%;
    vertical-align: top;
}
.template5-right {
    width: 48%;
    display:inline-block;
    vertical-align: top;
}
/* TEMPLATE 8 */

.template8 .image {
    margin-bottom: 5px;
}
.template8-left {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-right: 2%;
}
.template8-right {
    width: 48%;
    display: inline-block;
    vertical-align: top;
}